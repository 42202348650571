<template>
  <div>
    <b-card>
      <div class="d-lg-flex justify-content-between align-items-center">
        <h3 class="font-weight-bolder">
          รวมรหัสพัสดุ
        </h3>
        <!-- <div class="income-order-search order-search">
          <div class="anan-input search-input">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="filter"
                type="text"
                :placeholder="$t('search')"
                class="anan-input__input"
              >
              <div class="anan-input__suffix">
                <i class="anan-input__clear-btn anan-icon" />
                <i class="anan-input__suffix-icon anan-icon">
                  <i class="fal fa-search" />
                </i>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <!-- <div class="container"> -->
        <b-row class="match-height">
          <div class="col-md-6">
            <div>
              <div class="card p-2">
                <h4 class="font-weight-bolder mb-1">
                  {{ $t('search') }}
                </h4>
                <div>
                  <div class="d-flex align-items-center mb-1">
                    <!-- <div class="ml-2">
                    <p class="mb-0 font-weight-bolder text-right mr-2">
                      {{ $t('firstName') + '-' + $t('lastName') }}
                    </p>
                  </div> -->

                    <div class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25">
                      <input
                        v-model="search_val"
                        type="text"
                        class="anan-input__input"
                        @input="checkInput"
                        @keyup.enter="getTracking()"
                      >
                      <!-- @keyup.enter="getTracking()" -->
                    </div>

                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <div class="">
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary"
                        @click="getTracking()"
                      >
                        {{ $t('search') }}
                      </button>
                      <button
                        type="button"
                        :class="`${itemswait.length >= 2 ? 'anan-button anan-button--normal anan-button--primary ml-1': 'anan-button anan-button--normal anan-button--grey ml-1'}`"
                        :disabled="(itemswait.length < 2) || isdisabled"
                        @click="totalItem()"
                      >
                        {{ itemswait.length ? `รวมรหัสพัสดุ (${itemswait.length})`: 'รวมรหัสพัสดุ' }}
                      </button>
                      <button
                        type="button"
                        class="anan-button anan-button--normal anan-button--primary ml-1"
                        @click="CheckBoxitem()"
                      >
                        {{ $t('warehouseInfo') }}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div>
              <div class="card p-2">
                <h4 class="font-weight-bolder mb-1">
                  {{ $t('warehouseInfo') }}
                </h4>

                <div v-if="itemsfast">
                  <div class="d-flex align-items-center mb-1">
                    <div class="ml-2">
                      <p class="mb-0 font-weight-bolder text-right mr-1">
                        {{ $t('parcelCode') }}:
                      </p>
                    </div>

                    <div class="w-50">
                      {{ itemsfast.postnumber }}
                    </div>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <div class="ml-2">
                      <p class="mb-0 font-weight-bolder text-right mr-1">
                        {{ $t('arrange') }}
                      </p>
                    </div>

                    <div class="w-50">
                      {{ itemsfast.collocate_wh_name }}

                    </div>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <div class="ml-2">
                      <p class="mb-0 font-weight-bolder text-right mr-1">
                        วัน/เวลา รับเข้า <!--  {{ $t('arrange') }}-->
                      </p>
                    </div>

                    <div class="w-50">
                      {{ itemsfast.come_thaiwarehouse }}
                    </div>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <div class="ml-2">
                      <p class="mb-0 font-weight-bolder text-right mr-1">
                        น้ำหนัก รับเข้า
                      </p>
                    </div>

                    <div class="w-50">
                      {{ itemsfast.charge_per_save === itemsfast.charge_per_kilo ? $t('key-317'): $t('amount') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-row>
        <div
          v-if="items.length"
          class="mb-0 ml-1"
        >
          <b-form-checkbox
            v-model="selectAll"
            inline
            @input="CheckBoxAll(selectAll)"
          >
            {{ $t('key-105') }}
          </b-form-checkbox>
        </div>
        <b-table
          striped
          hover
          responsive
          :total-rows="totalRow"
          :per-page="perPage"
          :items="items"
          :fields="fields"
        >
          <template #cell(checkbox)="data">

            <b-form-checkbox
              v-if=" !data.item.id_payment"
              v-model="itemswait"
              :value="data.item"
            />
          </template>
          <!-- @input="CheckBoxitem"  -->

          <template #cell(detail)="data">
            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('key-22') }} :
                </p>
              </div>
              <div class="w-50">
                <small class="text-black">
                  {{ data.item.tag_id ? data.item.tag_id : '-' }}
                </small>
              </div>
            </div>

            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('systemOrder') }} :
                </p>
              </div>
              <div class="w-50">
                <b-link
                  v-if="data.item.order_text"
                  :to="{name : 'admin-order-detail', params: {id: data.item.order_id}}"
                  class="mb-0"
                >
                  {{ `ORD-${data.item.order_text}` }}
                </b-link>
                <span v-else>
                  -
                </span>
              </div>
            </div>

            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('parcelCode') }} :
                </p>
              </div>
              <div class="w-50">
                <b-link
                  :to="{name : 'admin-tracking-edit', params: {id: data.item._id}}"
                  class="mb-0"
                >
                  {{ data.item.postnumber }}
                </b-link>
                <!-- <i
                  v-clipboard:copy="data.item.postnumber"
                  :class="iconClass[data.item.postnumber] ? 'fal fa-clipboard-check text-success' : 'fal fa-copy text-primary'"
                  @click="handleCopy(data.item.postnumber)"
                /> -->
              </div>
            </div>

            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('lotSequence') }} :
                </p>
              </div>
              <div
                v-if="data.item.lot_id"
                class="w-50"
              >
                <p
                  class="mb-0 text-black badge badge-warning"
                  :class="{ 'mb-0 text-black badge badge-danger': data.item.transportType === 2 }"
                >
                  {{ checktype(data.item.transportType) }}
                </p>
                <b-link :to="{name : 'admin-warehouse-edit', params: {id:data.item.lot_name }}">
                  {{ data.item.lot_name ? data.item.lot_name: '-' }}
                </b-link>
                <small>/{{ data.item.lot_order ? data.item.lot_order: '-' }}</small>
              </div>
              <div
                v-else
                class="w-50"
              >
                <p class="mb-0">
                  -
                </p>
              </div>
            </div>

            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('woodPacking') }} :
                </p>
              </div>
              <div class="w-50">
                <span
                  v-if="data.item.packbox"
                  style="font-size: 11px;"
                  class="badge badge-success"
                >{{ $t('activate') }}</span>
                <span
                  v-else
                  style="font-size: 11px;"
                  class="badge badge-secondary"
                >{{ $t('key-328') }}</span>
              </div>
            </div>

            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('checkOrder') }} :
                </p>
              </div>
              <div class="w-50">
                <span
                  v-if="data.item.checkproduct"
                  style="font-size: 11px;"
                  class="badge badge-success"
                >{{ $t('activate') }}</span>
                <span
                  v-else
                  style="font-size: 11px;"
                  class="badge badge-secondary"
                >{{ $t('key-328') }}</span>
              </div>
            </div>

            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('type') }} :
                </p>
              </div>
              <div class="w-50">
                <p class="mb-0">
                  {{ chechproduct(data.item.product_type) }}
                </p>
              </div>
            </div>

            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('mnemonic') }} :
                </p>
              </div>
              <div class="w-50">
                <p class="mb-0 _9Ro5mPkey">
                  <!-- _9Ro5mPkey -->
                  {{ data.item.keyword ? data.item.keyword: '-' }}
                </p>
              </div>
            </div>

            <div class="d-flex">
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('remarks') }} :
                </p>
              </div>
              <div class="w-50">
                <p class="mb-0">
                  {{ data.item.remark ? data.item.remark: '-' }}
                </p>
              </div>
            </div>

            <div
              v-if="data.item.claim_status"
              class="d-flex text-danger border-top mt-25 pt-25"
            >
              <div class="">
                <p class="mb-0 text-right mr-50">
                  {{ $t('key-23') }} :
                </p>
              </div>
              <div class="w-50">
                <p class="mb-0">
                  {{ data.item.claim_detail ? data.item.claim_detail: '-' }}
                </p>
                <p class="mb-0">
                  {{ $t('quantity') }} : {{ data.item.claim_amount ? Commas(data.item.claim_amount): '0' }} ฿
                </p>
              </div>
            </div>
          </template>

          <template #cell(customerCode)="data">
            <b-link
              :to="{name : 'admin-employee-edit', params: {id: data.item.uid} }"
              class="mb-0"
            >
              <span
                v-if="data.item.notproduct"
                class="text-danger"
              >
                {{ $t('ownerlessGoods') }}
              </span>
              <span v-else>
                {{ data.item.username }}
              </span>

            </b-link>
          </template>

          <template #cell(calPrice)="data">
            <div class="d-flex">
              <div class="w-50">
                <div class="mb-0 text-right mr-50">
                  <small>{{ $t('dimensionsCm') }} :</small>
                </div>
              </div>
              <div class="w-50">
                <small class="mb-0">
                  ({{ data.item.width }} x {{ data.item.long }} x {{ data.item.height }}) x {{
                    data.item.product_amount }}
                </small>
              </div>
            </div>

            <div class="d-flex">
              <div class="w-50">
                <div class="mb-0 text-right mr-50">
                  <small>{{ $t('weightKilo') }} :</small>
                </div>
              </div>
              <div class="w-50">
                <small class="mb-0">
                  ({{ data.item.weight }}) x {{ data.item.product_amount }}

                </small>
              </div>
            </div>

            <div class="d-flex">
              <div class="w-50">
                <div class="mb-0 text-right mr-50">
                  <small>{{ $t('quantity') }} :</small>
                </div>
              </div>
              <div class="w-50">
                <small class="mb-0">
                  {{ data.item.product_amount }}
                </small>
              </div>
            </div>

            <div class="d-flex">
              <div class="w-50">
                <div class="mb-0 text-right mr-50">
                  <small>{{ checkcharge_per(data.item) }} :</small>
                </div>
              </div>
              <div class="w-50">
                <p class="mb-0">
                  {{ Commas(data.item.charge_per_save) }} ฿
                </p>
              </div>
            </div>
            <div class="mt-50">
              <div
                v-if="data.item.path_img.length"
                class="d-flex flex-wrap justify-content-center"
              >
                <div
                  v-for="url in data.item.path_img"
                  :key="url"
                  class="preview-image-container mr-25 d-flex justify-content-center"
                >
                  <img
                    :src="GetImg('postnumber_img',url)"
                    alt="Preview"
                    class=""
                    style="max-width: 100%; height: auto;"
                    @click="showPicture(data.item.path_img)"
                  >
                </div>
              </div>
            </div>

          </template>

          <template #cell(tranStatus)="data">
            <app-timeline>
              <app-timeline-item
                v-if="data.item.out_thaiwarehouse"
                :title="`${data.item.itemnumber_payment? data.item.itemnumber_payment: ''}  ${data.item.out_thaiwarehouse} : นำออกแล้ว`"
                variant="success"
              />
              <app-timeline-item
                v-if="data.item.paymentday"
                :title="`${data.item.paymentday} :`+$t('payShipping')"
                variant="success"
              />
              <app-timeline-item
                v-if="data.item.come_thaiwarehouse"
                :title="`${data.item.come_thaiwarehouse} : `+$t('arrivedThaiWarehouse')"
                variant="success"
              />

              <app-timeline-item
                v-if="data.item.out_chinesewarehouse"
                :title="`${data.item.out_chinesewarehouse} : `+$t('exitWarehouse')"
                variant="success"
              />

              <app-timeline-item
                v-if="data.item.come_chinesewarehouse"
                :title="`${data.item.come_chinesewarehouse} : `+$t('arrivedChinaWarehouse')"
                variant="success"
              />
            </app-timeline>
          </template>

          <template #cell(setup)="data">
            {{ data.item.collocate_wh_name ? data.item.collocate_wh_name: '-' }}
          </template>

          <template #cell(bill)="data">
            <b-link
              v-if="data.item.id_payment"
              :to="{name : 'shipment_payment_edit', params: {id: data.item.id_payment}}"
              class="mb-0"
            >
              {{ data.item.itemnumber_payment }}
            </b-link>
            <span
              v-else
              class="badge badge-warning text-black"
            >
              {{ $t('none') }}
            </span>
          </template>

          <template #cell(action)="data">
            <feather-icon
              icon="Edit3Icon"
              class="mr-50 text-primary cursor-pointer"
              @click="EditType(data.item._id)"
            />
          </template>
        </b-table>

        <div class="demo-spacing-0 d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getTracking()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BCard, BLink, BPagination, BOverlay, BRow, BButton, VBToggle, BFormCheckbox,
  BCol,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    VBToggle,
    BFormCheckbox,
    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      search: '',
      isActive: 'all',
      totalRows: 0,
      filter: null,
      items: [],
      showOver: false,
      isdisabled: false,
      search_val: null,
      selectAll: false,
      itemsfast: null,
      itemswait: [],

    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'checkbox', label: '', thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-left',
        },
        {
          key: 'detail', label: this.$t('details'), thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-left',
        },
        {
          key: 'customerCode', label: this.$t('customerCode'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'calPrice', label: this.$t('calculatePrice'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center',
        },
        {
          key: 'tranStatus', label: this.$t('shippingStatus'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'setup', label: this.$t('arrange'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'bill', label: this.$t('issueBill'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    // this.getData('all')
  },
  methods: {
    getTracking() {
      this.showOver = true
      if (this.search_val) {
        const params = {
        search_val: this.search_val,
        page: this.currentPage,
        perPage: this.perPage,
      }
      this.$http.get('/Combinetrack/GetPostNumber', { params })
        .then(response => {
          if (response.data.data.length) {
            // response.data.data.map(ele => ele.checkbox = false)
            this.items = response.data.data
            this.totalRows = response.data.total
            this.selectAll = false
          } else {
            this.selectAll = false
          }

          this.showOver = false
        }).catch(err => {
          console.log(err)
          this.showOver = false
        })
      } else {
        this.items = []
        this.totalRows = 0
        this.showOver = false
      }
      
    },
    checkInput() {
      if (/\s/.test(this.search_val) || /\t/.test(this.search_val)) {
        this.search_val = this.search_val.replace(/\s/g, '').replace(/\t/g, '')
      }
    },
    totalItem() {
      this.isdisabled = true
      this.showOver = true
      if (this.itemswait.length) {
        // eslint-disable-next-line no-underscore-dangle
        const ID = this.itemswait.map(ele => ele._id)
        const params = {
          ID,
        }
        this.$http.post('/Combinetrack/CombinePostNumber', params)
          .then(response => {
            if (response.data.status) {
              this.Success(response.data.data)
              this.getTracking()
              this.isdisabled = false
              this.showOver = false
              this.itemswait = []
            }
          })
          .catch(err => {
            console.log(err)
            this.SwalError(err.response.data.data)
            this.isdisabled = false
            this.showOver = false
          })
      }
      // if (this.items.some(ele => ele.checkbox === true && this.items.reduce((total, acc) => total + (acc.checkbox ? 1 : 0),0 ) >= 2)) {
      //   const ID = this.items.filter(ele => ele.checkbox === true).map(ele => ele._id)
      // }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    CheckBoxitem() {
      // eslint-disable-next-line prefer-destructuring
      this.itemsfast = this.itemswait[0]
      if (!this.itemsfast) {
        this.selectAll = false
      }
    },
    CheckBoxAll(data) {
      if (data) {
        this.itemswait = this.items.filter(ele => !ele.id_payment)
      } else {
        this.itemswait = []
      }

      // if (data) {
      //   this.items.map(ele => ele.checkbox = true)
      //   this.itemsfast = this.items.find(ele => ele.checkbox === true)
      // } else {
      //   this.items.map(ele => ele.checkbox = false)
      //   this.itemsfast = null
      // }
    },
    checktype(data) {
      if (data == 1) {
        return this.$t('truck')
      }
      return this.$t('ship')
    },
    checkcharge_per(item) {
      if (item.charge_per_save === item.charge_per_kilo) {
        return this.$t('calculateByKilo')
      }
      return this.$t('key-98')
    },
    chechproduct(item) {
      if (!item) {
        return '-'
      }
      if (this.$i18n.locale === 'th') {
        return item.pro_name
      } if (this.$i18n.locale === 'en') {
        return item.pro_name_en
      } if (this.$i18n.locale === 'cn') {
        return item.pro_name_cn
      }
      return item.pro_name
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      // console.log(picture)
      // eslint-disable-next-line no-shadow
      picture.forEach(picture => {
        const img = { path: this.GetImg('postnumber_img', picture) }
        this.images.push(img)
      })
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-tracking-edit', params: { id: data } })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              ID: data._id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

      <style lang="scss" scoped></style>
